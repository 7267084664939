<template>
  <div class="item-detail">
    <div class="main">
      <div class="wrapper" v-if="pageData.current.title">
        <div class="top">
          <div class="top-left">
            <h1 class="title">{{ pageData.current.title }}</h1>
            <div class="des">
              <div class="summary">
                <div style="color:#000">案例概要：</div>
                <div>
                  {{ pageData.current.summary }}
                </div>
              </div>
              <div class="time">
                完成时间：
                <span style="color: #666;">{{
                  pageData.current.publishDate &&
                  pageData.current.publishDate.slice(0, 10)
                }}</span>
              </div>
            </div>
          </div>
          <div class="top-right">
            <img
              :src="pageData.current.coverUrl"
              alt=""
              width="500px"
              height="211px"
            />
          </div>
        </div>

        <article v-html="pageData.current.detail"></article>
        <div class="bottom">
          <div class="left">
            <span class="jump" style="cursor:pointer"  @click="getData(pageData.pre.id)">上一页</span>
            <span
              class="url"
              @click="getData(pageData.pre.id)"
              :style="
                pageData.pre
                  ? { borderBottom: '1px solid #000' }
                  : { borderBottom: '1px solid transparent' }
              "
              >{{ pageData.pre !== null ? pageData.pre.title : "无" }}</span
            >
          </div>
          <div class="right">
            <span
              class="url"
              @click="getData(pageData.next.id)"
              :style="
                pageData.next
                  ? { borderBottom: '1px solid #000' }
                  : { borderBottom: '1px solid transparent' }
              "
              >{{ pageData.next !== null ? pageData.next.title : "无" }}</span
            >
            <span class="jump" style="cursor:pointer"  @click="getData(pageData.next.id)">下一页</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newsById } from "@/api/news.js";

export default {
  data() {
    return {
      pageData: {
        current: {
          treeId: null,
          title: "",
          summary: "",
          detail: "",
          publishData: "",
        },
        pre: {
          title: "",
        },
        next: {
          title: "",
        },
      },
      id: 1,
      categoryId: 1,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.categoryId = this.$route.query.categoryId;
    this.getData();
  },
  methods: {
    async getData(id) {
      if (id) {
        this.id = id;
      }
      let params = {};
      if (this.categoryId == 2) {
        params = { id: this.id };
      } else {
        params = { id: this.id, categoryId: this.categoryId };
      }
      let { data: res } = await newsById(params);
      this.pageData = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
